import React from "react"
import { Link } from "gatsby"
import { Content } from "antd/lib/layout/layout"
import { Breadcrumb, Typography } from "antd"
import ListCompany from "./listCompany"
const { Title } = Typography

function SuperListCompany({ location }) {
  return (
    <div>
      <Content>
        <div
          style={{
            backgroundColor: "#FFFFFF",
            padding: "10px",
          }}
        >
          <Breadcrumb separator={">"}>
            <Breadcrumb.Item>
              <Link to="/home">HOME</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>掲載管理</Breadcrumb.Item>
            <Breadcrumb.Item>企業管理</Breadcrumb.Item>
          </Breadcrumb>
          <Title
            level={2}
            className="!text-2xl !pt-6 !font-normal !leading-[33px]] !tracking-[0.3px]"
          >
            企業管理
          </Title>
        </div>
        <ListCompany location={location} />
      </Content>
    </div>
  )
}

export default SuperListCompany
