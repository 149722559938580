import moment from "moment"
import React, { useState } from "react"
import arrayFormat from "../../../common/arrayUseInProject/arrayFormat"
import queryString from "query-string"
import { Badge, Button } from "antd"
import UnlockedButton from "./UnlockedButton"

export const defineTableListCompany = ({ location, handleGetListCompany }) => {
  const query = queryString.parse(location.search)
  return [
    {
      title: "企業ID",
      dataIndex: "id",
      width: 100,
      render: (text) => <div className="textCustom">{text}</div>,
    },
    {
      title: "企業名",
      dataIndex: ["name"],
      width: 300,
      render: (text) => <div className="textCustom">{text}</div>,
    },
    {
      title: "業態",
      dataIndex: "user_type",
      width: 170,
      render: (user_type) => (
        <div className="textCustom" style={{ whiteSpace: "nowrap" }}>
          {arrayFormat.filter((el) => el.type == user_type)[0]?.name}
        </div>
      ),
    },
    // {
    //   title: "ステータス",
    //   dataIndex: "status",
    //   width: 110,
    //   render: (text) => <div className="textCustom">{text}</div>,
    // },
    {
      title: "登録日",
      dataIndex: "created_at",
      sorter: true,
      sortOrder: query?.sorterField == "created_at" ? query?.order : null,
      width: 108,
      render: (text) => (
        <div className="textCustom">{moment(text).format("YYYY/MM/DD")}</div>
      ),
    },
    {
      title: <div style={{ whiteSpace: "nowrap" }}>{"最終ログイン"}</div>,

      dataIndex: "last_action",
      sortOrder: query?.sorterField == "last_action" ? query?.order : null,
      sorter: true,
      width: 150,
      render: (text) => (
        <div className="textCustom">{moment(text).format("YYYY/MM/DD")}</div>
      ),
    },
    {
      title: <div style={{ whiteSpace: "nowrap" }}>{"商品登録数"}</div>,

      dataIndex: "total_product",
      width: 150,
      render: (text) => <div className="textCustom">{text}</div>,
    },
    {
      title: <div style={{ whiteSpace: "nowrap" }}>{"口座登録"}</div>,

      width: 120,
      render: (_, record) => {
        return <div>{record?.branch_number ? "設定済み" : "未設定"}</div>
      },
    },
    {
      title: <div style={{ whiteSpace: "nowrap" }}>{"支払情報"}</div>,

      dataIndex: "payment_information",
      width: 120,
      render: (_, record) => {
        return (
          <div>
            {record?.np_payment || record?.gmo_payment ? "設定済み" : "未設定"}
          </div>
        )
      },
    },
    {
      title: <div style={{ whiteSpace: "nowrap" }}>{"手数料設定"}</div>,
      width: 120,
      render: (_, record) => {
        return <div>{record?.applicationFee_check ? "設定済み" : "未設定"}</div>
      },
    },
    {
      title: <div style={{ whiteSpace: "nowrap" }}>{"ステータス"}</div>,
      dataIndex: "Status",
      key: "Status",
      width: 120,
      render: (_, record) => {
        return (
          <div>
            {record?.operation_manager == "deactivate" ? (
              <div className="w-[200px]">
                <span>
                  {" "}
                  <Badge status="error" text="営業停止" />
                </span>
                <span className="pl-[10px]">
                  {" "}
                  <UnlockedButton
                    record={record}
                    handleGetListCompany={handleGetListCompany}
                  />
                </span>
              </div>
            ) : (
              <Badge status="success" text="営業中" />
            )}
          </div>
        )
      },
    },
  ]
}
